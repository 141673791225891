
import React,{useState,useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import styles from '../../css/Modal.module.css';

const Modal = ({modalclose, v_id}) => {


    const [detailInfo,setInfo] = useState()
    const [notNull,setnotNull] = useState(false)


    const get_detail = async() => {
        try{
            const get_url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/item_detail/'+v_id
            // const get_url = 'http://localhost:8000/item_detail/'+v_id
            console.log(get_url)

            let res = await axios.get(get_url);
            let result = res.data;
            // let json_result = JSON.stringify(result)

            if(result.length!==0){
                setnotNull(true)
            }
            setInfo(result);

            console.log(result)
            // console.log(json_result)
            
        }catch(e){
            console.log(e)
        }

    };
    useEffect(()=>{
        // get_youtubeList()
        get_detail()
    },[])

    if(notNull){
  return (
    <div className={styles.parent}>
        <div className={styles.container}>
            <button className={styles.close} onClick={modalclose}>
                X
            </button>
            <p className={styles.title}>{detailInfo.title}</p>
            <div className={styles.ai_con}>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box}><span>기사 요약</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.AI_summary}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box}><span>판단</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p style={{fontWeight:"bold",color:"red"}}>{detailInfo.AI_result}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box}><span>이유</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.AI_reason}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box}><span>키워드</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.AI_keyword}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box}><span>언급량(%)</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.AI_percent}</p>
                    </div>
                </div>

            </div>
            <div className={styles.com_con}>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box2}><span>댓글 요약</span></div>
                    </div>
                    <div className={styles.right_con}>
                        {detailInfo.com_summary===""
                        ?<p>댓글분석은 댓글이 10개 이상일시 제공됩니다.</p>
                        :<p>{detailInfo.com_summary}</p>
                        }
                        
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box2}><span>판단</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p style={{fontWeight:"bold",color:"red"}}>{detailInfo.com_result}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box2}><span>이유</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.com_reason}</p>
                    </div>
                </div>
                <div className={styles.detail_con}>
                    <div className={styles.left_con}>
                        <div className={styles.small_box2}><span>댓글 감정</span></div>
                    </div>
                    <div className={styles.right_con}>
                        <p>{detailInfo.com_emotion}</p>
                    </div>
                </div>

            </div>

        </div>
    </div>
  );}
  return null
};

export default Modal;

//아래는 styled-components를 통한 스타일링
