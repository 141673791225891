import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Chart from 'chart.js';
import { PureComponent } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import styles from '../../css/testChart.css'

const TestChart = ( ) => {

  const [graphData1,setgraphData1] = useState([])
  const [graphData2,setgraphData2] = useState([])
  const [notNull,setnotNull] = useState(false)
  

  const get_graphData = async() => {
    try{
        // let res = await axios.get('http://localhost:8000/get_youtuber');
        let res = await axios.get('https://youtube-poc.koreacentral.cloudapp.azure.com/api/graph_data');
        let result = res.data;
        // let json_result = JSON.stringify(result)
        setgraphData1(result);
        
        if(result.length!==0){
            setnotNull(true)
        }
    }catch(e){
        console.log(e)
    }

};

const get_graphData2 = async() => {
  try{
      // let res = await axios.get('http://localhost:8000/get_youtuber');
      let res = await axios.get('https://youtube-poc.koreacentral.cloudapp.azure.com/api/graph_data2');
      let result = res.data;
      // let json_result = JSON.stringify(result)
      setgraphData2(result);
      
      if(result.length!==0){
          setnotNull(true)
      }
  }catch(e){
      console.log(e)
  }

};

  useEffect(()=>{
    
    get_graphData()
    get_graphData2()
    // get_younewlist()
  },[])

  // const monthData = [
  //   { month: '9월', positive_count: 9, negative_count: 10, neutral_count: 2 },
  //   { month: '10월', positive_count: 19, negative_count: 8, neutral_count: 8 },
  //   { month: '11월', positive_count: 10, negative_count: 10, neutral_count: 7 },
  // ];
  // let total = 0;
  // graphData2.forEach(item => {
  //   total += item.positive_count + item.negative_count + item.neutral_count;
  // });

  const totalPositive = graphData2.reduce((sum, item) => sum + item.positive_count, 0);
  const totalNegative = graphData2.reduce((sum, item) => sum + item.negative_count, 0);
  const totalNeutral = graphData2.reduce((sum, item) => sum + item.neutral_count, 0);
  const totalSum = totalPositive + totalNegative + totalNeutral;

  // 레이블 길이 제한
  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    let label = payload.value;
    const maxLength = 200; // 최대 표시할 문자 수
    if (label.length > maxLength) {
      label = label.slice(0, maxLength) + '...'; // 길이를 초과하는 경우 생략 기호 추가
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {label}
        </text>
      </g>
    );
  };

  if(notNull){
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        <div className="barChart" style={{ alignItems: 'center', marginTop:'10px' }}>
          <h2>언론사별 긍부정 비교(최근 90일)</h2>
          <BarChart width={800} height={300} data={graphData1}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="channelTitle" height={80} tick={<CustomizedAxisTick />} interval={0}/>
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="positive_count" name="긍정" stackId="a" fill="#213951" barSize={20}  />
            <Bar dataKey="negative_count" name="부정" stackId="a" fill="#FC6C62" barSize={20}  />
            <Bar dataKey="neutral_count" name="중립" stackId="a" fill="#A5A39A" barSize={20} />
          </BarChart>
        </div>

        <div className="lineChart-Report">
          <h2>월별 긍부정 추이(최근 3개월)</h2>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop:'10px' }}>
            <div className="lineChart" style={{ alignItems: 'center'}}>
              <LineChart width={600} height={300} data={graphData2}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" tickFormatter={(value) => `${value}월`} />
                <YAxis/>
                <Tooltip labelFormatter={(value) => `${value}월`} />
                <Legend />
                <Line type="monotone" dataKey="positive_count" name="긍정" stroke="#213951" activeDot={{ r: 8 }}/>
                <Line type="monotone" dataKey="negative_count" name="부정" stroke="#FC6C62" activeDot={{ r: 8 }}/>
                <Line type="monotone" dataKey="neutral_count" name="중립" stroke="#A5A39A" activeDot={{ r: 8 }}/>
              </LineChart>
            </div>

            <div className="Report" style={{ alignItems: 'center' }} >
              <div className="Report-total" style={{
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                backgroundColor: '#213951',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <span >총</span>
                <span >{totalSum}건</span>
              </div>
              <div className="Report-list" style={{alignItems: 'center'}}>
                <div> 긍정: {totalPositive}건</div>
                <div> 부정: {totalNegative}건</div>
                <div> 중립: {totalNeutral}건</div>
              </div>
            </div>
          </div>
        </div>



      </div>
    );
  
}}

    
export default TestChart;