import React, { useState, useRef } from 'react';
import './App.css';


function RegisterComponent() {
    const [CNum, setCNum] = useState(''); // Company Number
    const [PCode, setPCode] = useState(''); // Private Code
    const [Pname, setPname] = useState(''); // Person Name
    // const [PCPhone, setPCPhone] = useState(''); // Person Phone

    // 등록하기 버튼 눌렀을 때 동작 함수
    const handleRegister = async (e) => {
        e.preventDefault();
        // 서버에 데이터를 보내 사용자를 등록하는 로직 추가
        // alert(`사번: ${CNum}, 인증번호: ${PCode}, 이름: ${Pname}, 전화번호: ${PCPhone}`);
        
        // 입력 검증
        if (!CNum || CNum.length !== 8) {
            alert("로그인 ID를 8자리로 입력해주세요.");
            return;
        }
        if (!PCode || PCode.length !== 4) {
            alert("개인 인증코드를 4자리로 입력해주세요.");
            return;
        }
        if (!Pname) {
            alert("이름을 입력해주세요.");
            return;
        }
        // if (!PCPhone || PCPhone.length !== 13) {
        //     alert("전화번호를 010-xxxx-xxxx 형식으로 입력해주세요.");
        //     return;
        // }
        
        // 사용자 데이터
        const userData = {
            CNum: CNum,
            PCode: PCode,
            Pname: Pname,
            // PCPhone: PCPhone
        };

        // fetch를 사용하여 서버에 POST 요청
        try {
            const response = await fetch('https://youtube-poc.koreacentral.cloudapp.azure.com/api/register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData) // 데이터를 JSON 형식으로 변환
            });

            // 응답 처리
            if(response.ok) {
                const data = await response.json();
                alert('등록에 성공했습니다!');
                // 추가적인 성공 처리 로직
            } else {
                alert('등록에 실패했습니다.');
                // 추가적인 실패 처리 로직
            }
        } catch (error) {
            console.error('서버 요청 중 오류가 발생했습니다.', error);
        }
    };

    // const prevPCPhone = useRef(PCPhone); //전화 번호 입력중 백스페이스 눌렀을때 사용 변수
    
    // //전화번호 입력간 010 자동 입력용
    // const handlePhoneChange = (e) => {
    //     let input = e.target.value;
    
    //     // 백스페이스 키 확인
    //     if (prevPCPhone.current.length > input.length) {
    //         // '010-' 문자를 포함하면 제거한 상태로 다시 저장
    //         input = input.replace(/^010-/, '');
    //         setPCPhone(input);
    //         prevPCPhone.current = input;
    //         return;
    //     }
    
    //     // 숫자만 입력받기
    //     input = input.replace(/[^0-9]/g, '');
    
    //     // 입력값 제한
    //     if (input.length > 8) {
    //         input = input.slice(0, 8);
    //     }
    
    //     // 8개의 숫자를 모두 입력했을 때 '010-'을 앞에 추가
    //     if (input.length === 8) {
    //         input = "010-" + input.slice(0, 4) + "-" + input.slice(4);
    //     } else if (input.length > 4) {
    //         // 5번째 숫자 이후 입력 시
    //         input = input.slice(0, 4) + "-" + input.slice(4);
    //     }
    
    //     setPCPhone(input);
    //     prevPCPhone.current = input;
    // };

    //ID와 비번에서 숫자만 입력받는 용도
    const handleOnlyNumbers = (e, setStateFunc) => {
        const input = e.target.value;
        const filteredInput = input.replace(/[^0-9]/g, ''); // 숫자가 아닌 문자 제거
        setStateFunc(filteredInput);
    }

    //main body 보여주는 화면 구성
    return (
        <div className="App">
            <h1>사용자 등록</h1>
            {/* 사용자 등록하기 버튼 액션 핸들폼 */}
            <form onSubmit={handleRegister}>
                <div className="input-group">
                    <label>로그인 ID</label>
                    <input
                        type="text"
                        value={CNum}
                        onChange={(e) => handleOnlyNumbers(e, setCNum)}
                        placeholder="사번뒤4자리 + 전화번호뒤4자리"
                        autoFocus // 사이트 접속시 자동으로 포커스 입력 편의
                        maxLength={8}
                    />
                </div>
                <div className="input-group">
                    <label>개인 인증코드</label>
                    <input
                        type="password"
                        value={PCode}
                        onChange={(e) => handleOnlyNumbers(e, setPCode)}
                        placeholder="개인 인증코드 4자리"
                        maxLength={4}
                    />
                </div>
                <div className="input-group">
                    <label>이름</label>
                    <input
                        type="text"
                        value={Pname}
                        onChange={(e) => setPname(e.target.value)}
                        placeholder="사용자 이름"
                    />
                </div>
                <div className="button-container">
                    <button type="submit">등록하기</button>
                </div>
            </form>
        </div>
    );
}

export default RegisterComponent;


{/* <div className="input-group">
<label>전화번호</label>
<input
    type="tel"
    value={PCPhone}
    onChange={handlePhoneChange}
    placeholder="010-xxxx-xxxx"
    maxLength={13}
/>
</div> */}