import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Chart from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

const UnewsChart = ( ) => {

    const [MonthData, setMonthData] = useState({});
    const [ChData, setChData] = useState({});
    
    useEffect(() => {
        const fetchData = async () => {
            let url = '';
            url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_total';
            try {
                const response = await axios.get(url);
                const result = response.data;
                // 
                const groupDataByMonth = (data) => {
                    const groupedData = {};
                    data.forEach((item) => {
                    const date = new Date(item.publishedAt);
                    const monthYear = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
                    
                    if (groupedData[monthYear]) {
                        groupedData[monthYear]++;
                    } else {
                        groupedData[monthYear] = 1;
                    }
                    });
                    return groupedData;
                };

                if (result.length !== 0) {
                    // 월별 데이터
                    const groupedMonthData = groupDataByMonth(result);
                    setMonthData(groupedMonthData);
                    
                    
                    // 채널 데이터
                    const channelData = countAIResultsByChannel(result);
                    const topChannels = getTopChannels(channelData, 10);
                    setChData(topChannels);
                    // ////////////////////////// 
                    const countAIResultsByChannel = (result) => {
                        const channelData = {};
                        result.forEach((item) => {
                        const channelTitle = item.channelTitle;
                        if (channelData[channelTitle]) {
                            channelData[channelTitle]++;
                        } else {
                            channelData[channelTitle] = 1;
                        }
                        });
                        return channelData;
                    };
                    const getTopChannels = (channelData, count) => {
                        const sortedChannels = Object.keys(channelData).sort(
                        (a, b) => channelData[b] - channelData[a]
                        );
                        const topChannels = {};
                        for (let i = 0; i < Math.min(count, sortedChannels.length); i++) {
                        const channel = sortedChannels[i];
                        topChannels[channel] = channelData[channel];
                        }
                        return topChannels;
                    };
                    // //////////////////////////

                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchData();
    }, []);
    
      const MonthChartData = {
        labels: Object.keys(MonthData),
        datasets: [
          {
            label: '월별 AI_result 건수',
            data: Object.values(MonthData),
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
          },
        ],
      };
      const ChChartData = {
        labels: Object.keys(ChData),
        datasets: [
          {
            label: '채널별 AI_result 건수',
            data: Object.values(ChData),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      };
    
      return (
        <div>
            <div>
                <h2>월별 AI_result 건수 </h2>
                <Line data={MonthChartData} />
            </div>
            <div>
                <h2>상위 10개 채널별 AI_result 건수 </h2>
                <Bar data={ChChartData} />
            </div>
        </div>
      );

    };
    
export default UnewsChart;