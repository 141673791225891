// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Report {
    font-family: 'NanumSquareRoundR';
}

.Report-total {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #213951;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.Report-total span {
    font-size: 28px;
}

.Report-list {
    font-size: 16px;
    color: #213951;

}

.barChart.h2, .lineChart-Report.h2 {
    font-size: 16px;
    color: #213951;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* 화면 너비가 600px 이하일 때 글자 크기 조정 */
@media (max-width: 600px) {
    body {
      font-size: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/testChart.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;;AAElB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA,gCAAgC;AAChC;IACI;MACE,eAAe;IACjB;EACF","sourcesContent":[".Report {\r\n    font-family: 'NanumSquareRoundR';\r\n}\r\n\r\n.Report-total {\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 50%;\r\n    background-color: #213951;\r\n    color: white;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.Report-total span {\r\n    font-size: 28px;\r\n}\r\n\r\n.Report-list {\r\n    font-size: 16px;\r\n    color: #213951;\r\n\r\n}\r\n\r\n.barChart.h2, .lineChart-Report.h2 {\r\n    font-size: 16px;\r\n    color: #213951;\r\n    margin-top: 10px;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n/* 화면 너비가 600px 이하일 때 글자 크기 조정 */\r\n@media (max-width: 600px) {\r\n    body {\r\n      font-size: 10px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
