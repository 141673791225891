import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import RegisterComponent from './user_add';
// import CallChat_component from './callchat';
import Header from "./components/layout/Header";
import ContentBody from "./components/layout/ContentBody";
import styled from "styled-components";

  // user_add.js에서 RegisterComponent를 가져옵니다.

function MainComponent() {
    const MiddleBox = styled.div`
    display: flex;
    backgorund-color:gray;
    flex-direction: row;
    align-items: flex-start;
    height:100%
  
    `;
    const [CNum, setCNum] = useState(''); // Company Number
    const [PCode, setPCode] = useState(''); // Private Code
    const [Pname, setPname] = useState(''); // Private Name
    const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 전/후 확인용
    // const navigate = useNavigate();
    const activityTimeout = useRef(null); // useRef를 사용하여 activityTimeout 참조 생성

    
    // 로그아웃 처리 함수
    const handleLogout = () => {
        setIsLoggedIn(false); // 로그아웃 상태로 변경
        setCNum('');
        setPCode('');
        alert('30분동안 활동이 없어 자동으로 로그아웃되었습니다.');
    };

    // 활동 감지 및 로그아웃 타이머 리셋 함수
    const handleActivity = () => {
        clearTimeout(activityTimeout.current); // 현재 타이머를 취소
        activityTimeout.current = setTimeout(handleLogout, 30 * 60 * 1000); // 새로운 타이머 설정
    };

    useEffect(() => {
        if (isLoggedIn) {
            handleActivity(); // 로그인 시 초기 활동 감지 타이머 설정

            // 이벤트 리스너 추가
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('keydown', handleActivity);

            // 컴포넌트 언마운트 시 이벤트 리스너 제거 및 타이머 취소
            return () => {
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                clearTimeout(activityTimeout.current);
            };
        }
    }, [isLoggedIn]); // isLoggedIn이 변경될 때마다 실행


    // 버튼 눌러서 로그아웃 처리
    const handleButtonLogout = () => {
        setIsLoggedIn(false); // 로그아웃 상태로 변경
        setCNum('');
        setPCode('');
        alert('정상 로그아웃되었습니다.');
    };

    // 접속하기 버튼 눌렀을때 로그인 동작 함수
    const handleSubmit = async (e) => {
        e.preventDefault();
        // alert(`이름: ${CNum}, 인증번호: ${PCode}`);
        // 사용자 데이터
        const userData = {
            CNum: CNum,
            PCode: PCode,
        };

        // fetch를 사용하여 서버에 POST 요청
        try {
            const response = await fetch('https://youtube-poc.koreacentral.cloudapp.azure.com/api/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData) // 데이터를 JSON 형식으로 변환
            });

            // 응답 처인
            if(response.ok) {
                const data = await response.json();
                // alert(`로그인 성공했습니다! 환영합니다, ${data.username}님!`);
                setPname(data.username)
                setIsLoggedIn(true);
                // navigate('/content');  // 리디렉션
                // 추가적인 성공 처리 로직
            } else {
                alert('로그인 실패했습니다.');
                // 추가적인 실패 처리 로직
            }
        } catch (error) {
            console.error('서버 요청 중 오류가 발생했습니다.', error);
        }
    };

    // 로그인 후 창을 보여주는 화면으로.... 두 선임님 작업하신 App.js 코드는 여기로...)
    if(isLoggedIn) {
        return (
            <div>
                <Header nameUser={Pname} handleButtonLogout={handleButtonLogout} ></Header>

                <ContentBody/>
            </div>
        );
    }
    // 로그인 후 창 End

   // 로그인 전 보여주는 화면
   // 사번과 개인인증코드는 숫자만 입력되는 함수 (탭키 이동 포함) -> 필요없을시 삭제
    const handleKeyDown = (e) => {
      if (!/[0-9]/.test(e.key) && e.key !== 'Tab'  && e.key !== 'Backspace' && e.key !== 'Enter') {
          e.preventDefault();
      }
    };
  
    if(!isLoggedIn) {
        return (
            <div className="App">
                <h1>언론 리스크 대응 시스템(PoC)</h1>
                {/* 접속하기 버튼 액션 핸들폼 */}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>ID</label>
                        <input
                            type="text"
                            value={CNum}
                            maxLength={8}
                            onKeyDown={handleKeyDown} // 숫자만 입력되게 막는건데 필요없을 시 삭제
                            onChange={(e) => setCNum(e.target.value)}
                            placeholder="Login ID"
                            autoFocus // 사이트 접속시 자동으로 포커스 입력 편의
                        />
                    </div>
                    <div className="input-group">
                        <label>인증코드</label>
                        <input
                            type="password"
                            value={PCode}
                            maxLength={4}
                            onKeyDown={handleKeyDown} // 숫자만 입력되게 막는건데 필요없을 시 삭제
                            onChange={(e) => setPCode(e.target.value)}
                            placeholder="Password"
                        />
                    </div>
                    <div className="button-container">
                    <button className="loginButton" type="submit">접속하기</button>
                    </div>
                </form>
                <footer className="footer">
                    * 이 시스템은 KT 홍보실 내 사전 허가된 직원만 이용 가능합니다.<br />
                    * 접속 관련 문의 : 남우종 차장 (010-7300-0746)
                </footer>
            </div>
        );
    }
}

// 메인페이지 외의 주소를 직접 입력해서 접근하는것 방지
function App() {

  return (
      <BrowserRouter>
          <Routes>
          <Route path="/" element={<MainComponent />} />
          <Route path="/user_add" element={<RegisterComponent />} />
          {/* <Route path="/call_chat" element={<CallChat_component />} /> */}
          {/* <Route path="/content" element={<ContentBody />} /> */}
          </Routes>
      </BrowserRouter>
  );
}

export default App;
