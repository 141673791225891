// import React, { useState } from "react";
import React from "react";
import { useState, useEffect } from "react";

import UnewsList from "./UnewsList"
import UnewsChart from "./UnewsChart"
// import sortedList from "./sortedList"
// import UyoutubeList from "./UyoutubeList"

import styled from "styled-components";
import styles from '../../css/ContentBody.css'
// import '../../css/ContentBody.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faArrowDownWideShort, faDownload, faCircle } from "@fortawesome/free-solid-svg-icons";
import TestChart from "./testChart";



function ContentBody() {

  const [findword, setFindword] = useState("");
  const [youtuberNum, setyoutuberNum] = useState(0);
  const [newsNum, setnewsNum] = useState(0);
  const handleFindWord = (event) => {
    event.preventDefault();
    setFindword(event.target.value);
  }

  const [selectedPeriod, setSelectedPeriod] = useState('1주일'); // 초기 조회 기준
  const [sortOption, setSortOption] = useState('publishedAt');  // 초기 정렬 기준
  const [downURL, setdownURL] = useState("");


  const handlePeriodClick = (period) => {
    setSelectedPeriod(period);// 선택한 조회 기간에 따른 데이터 업데이트
    // console.log(period)
  };
  const handleSortClick = (option) => {
    setSortOption(option);// 선택한 정렬 기준에 따라 표출
    console.log(option)
  };

  // 페이징 관련 상태 변수
  const [pageNumber, setPageNumber] = useState(1); // 현재 페이지 번호
  const [pageSize, setPageSize] = useState(10); // 페이지 크기

  useEffect(()=>{
    // get_youtubeList()
    setTimeout(function() {
      let url = '';

      if (selectedPeriod === '1일') {
      url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/download_csv_1day';
      // temp_url = 'http://localhost:8000/download_csv_1day';
    } else if (selectedPeriod === '1주일') {
      url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/download_csv_7day';
      // temp_url = 'http://localhost:8000/download_csv_7day';
    } else if (selectedPeriod === '1개월') {
      url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/download_csv_30day';
      // temp_url = 'http://localhost:8000/download_csv_30day';
    } else if (selectedPeriod === '전체') {
      url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/download_csv_total';
      // url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_1day';
    }
    setdownURL(url)
    // console.log(temp_url)
      
    }, 100);
},[selectedPeriod])

  return (
      <ConBody>
        <div id="modal">

          {/* 조회 버튼 */}
          <div className="period" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

            <div className="period-content1" style={{ display: 'flex', alignItems: 'center'}}>
              <FontAwesomeIcon icon={faClock} style={{ color: 'gray', fontSize: '16px', marginRight: '10px' }} />
              <p style={{ color: 'gray', fontSize: '16px', fontWeight: 'bold', marginRight: '10px' }}>조회</p>

              <PeriodButton
                // className={selectedPeriod === '1일' ? 'active' : ''}
                className={`period-button ${selectedPeriod === "1일" ? "active" : ""}`}
                onClick={() => handlePeriodClick('1일')}>1일</PeriodButton>
              <PeriodButton
                // className={selectedPeriod === '1주일' ? 'active' : ''}
                className={`period-button ${selectedPeriod === "1주일" ? "active" : ""}`}
                onClick={() => handlePeriodClick('1주일')}>1주일</PeriodButton>
              <PeriodButton
                // className={selectedPeriod === '1개월' ? 'active' : ''}
                className={`period-button ${selectedPeriod === "1개월" ? "active" : ""}`}
                onClick={() => handlePeriodClick('1개월')}>1개월</PeriodButton>
              <PeriodButton
                // className={selectedPeriod === '전체' ? 'active' : ''}
                className={`period-button ${selectedPeriod === "전체" ? "active" : ""}`}
                onClick={() => handlePeriodClick('전체')}>전체</PeriodButton>
            </div>
            <div className="period-content2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <a href={downURL} download>
              <button className={`downloadButton`}>
                <FontAwesomeIcon icon={faDownload}/> 내려받기
              </button>
              </a>
            </div>
          </div>

          {/* 정렬 버튼 */}
          <div className="sort-buttons" style={{ display: 'flex', alignItems: 'center',  justifyContent: 'flex-end', gap: '10px' }}>
            <FontAwesomeIcon icon={faArrowDownWideShort} style={{ color: 'gray', fontSize: '16px'}} />
            <p style={{ color: 'gray', fontSize: '16px', fontWeight: 'bold', marginRight: '10px' }}>정렬</p>
            <SortButton
              className={sortOption === 'publishedAt' ? 'active' : ''}
              onClick={() => handleSortClick('publishedAt')}>최근</SortButton>
            <SortButton
              className={sortOption === 'level' ? 'active' : ''}
              onClick={() => handleSortClick('level')}>주요언론사</SortButton>
            <SortButton
              className={sortOption === 'viewCount' ? 'active' : ''}
              onClick={() => handleSortClick('viewCount')}>조회수</SortButton>
            <SortButton
              className={sortOption === 'likeCount' ? 'active' : ''}
              onClick={() => handleSortClick('likeCount')}>좋아요</SortButton>
            <SortButton
              className={sortOption === 'commentCount' ? 'active' : ''}
              onClick={() => handleSortClick('commentCount')}>댓글수</SortButton>
          </div>

          <div className={styles.colorLegend} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' , marginRight: '10px' }}>
            <div className={styles.legendItem} style={{ fontSize: '14px' }}>
              <FontAwesomeIcon icon={faCircle} style={{ color: '#213951' }} />긍정</div>
            <div className={styles.legendItem} style={{ fontSize: '14px' }}>
              <FontAwesomeIcon icon={faCircle} style={{ color: '#FC6C62' }} />부정</div>
            <div className={styles.legendItem} style={{ fontSize: '14px' }}>
              <FontAwesomeIcon icon={faCircle} style={{ color: '#A5A39A' }} />중립</div>  
          </div>

          <div className="List-Chart" style={{ display:'start', alignItems: 'center' }}>
            <div className="blockTitle" style={{width:"80%", margin:"0 auto", display:"flex"}}>
              <div class="left-block">
                <h2>뉴스 컨텐츠</h2>
              </div>
              <div class="right-block">
                <h2>AI 분석 결과</h2>
              </div>
            </div>
            <div className="youtubeNewsList" >
              <UnewsList selectedPeriod={selectedPeriod} propFunction={setnewsNum} sortOption={sortOption}/>


              
            </div>

            {/* 그래프 추가 */}
            <div className="graphContainer" style={{ width: '80%', margin: '0 auto', alignItems: 'center' }}>
              <TestChart />
            </div>
          </div>
          
      </div>
    </ConBody>
  );
}

export default ContentBody;
const ConBody = styled.div`  
  display:flex;
  flex: 1 1 1 1 auto;
  flex-direction: column;
  text-align : center;
  font-family: 'NanumSquareRoundEB';
  font-size:10px;
  background-color: white;
`;
// const TestChart = styled.div`
//   display: flex;
//   flex-direction: column;
//   text-align : center;
//   font-family: 'NanumSquareRoundEB';
// `;

// 조회 버튼 스타일 
const PeriodButton = styled.button`
  background-color: #D7D6D2; 
  color: #213951;
  padding: 5px 10px; /* 안쪽 여백 설정 */

  font-size: 16px; /* 글자 크기 설정 */
  cursor: pointer; /* 마우스 커서 모양 설정 */
  outline: none; /* 클릭 포커스 스타일 제거 */
  width: 90px; /* 너비 설정 */
  height: 30px; /* 높이 설정 */
  border: none; /* 테두리 없음 */
  border-radius: 10px; /*30% */
  margin-right: 10px;

  &.active {
    background-color: #213951; /* 남색 */
    color: white;
  }

  &:hover {
    background-color: #213951; 
    color: white;
  }
`;

// 정렬 버튼 스타일 
const SortButton = styled.button`
  background-color: #D7D6D2; /* 라이트그레이*/
  color: #213951;
  padding: 5px 10px; /* 안쪽 여백 설정 */
  font-size: 14px; /* 글자 크기 설정 */
  cursor: pointer; /* 마우스 커서 모양 설정 */
  outline: none; /* 클릭 포커스 스타일 제거 */
  width: 90px; /* 너비 설정 */
  height: 30px; /* 높이 설정 */
  border: none; /* 테두리 없음 */
  border-radius: 30px; /*30% */
  margin-right: 10px;
  &.active {
    background-color: #213951; /* 남색 */
    color: white;
  }

  &:hover {
    background-color: #213951; 
    color: white;
`;