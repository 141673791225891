import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UnewsItem from './UnewsItem';
import styled from "styled-components";

const UnewsList = ({ selectedPeriod, propFunction, sortOption }) => {
  const [newlist, setNewlist] = useState([]);

  // paging 을 위한 변수 선언
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(10); // 한페이지당 보여줄 갯수
  
  // 페이지 번호 범위 상태
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      let url = '';
      switch (selectedPeriod) {
        case '1일':
          url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_1day';
          break;
        case '1주일':
          url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_7day';
          break;
        case '1개월':
          url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_30day';
          break;
        case '전체':
          url = 'https://youtube-poc.koreacentral.cloudapp.azure.com/api/get_list_total';
          break;
        default:
          // 기본값 혹은 오류 처리
          return;
      }

      try {
        const response = await axios.get(url);
        const result = response.data;
        if (result.length) {
          const sortedNews = sortNews(result); // 뉴스를 정렬합니다
          setNewlist(sortedNews); // 정렬된 뉴스를 상태로 설정합니다
          propFunction(sortedNews.length); // 상위 컴포넌트에 데이터 길이를 전달합니다
        } else {
          setNewlist([]); // 뉴스가 없으면 문구 표출
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    // 조회기간이나 정렬 방식을 바꿀때마다 1페이지로 돌리기 위한 부분
    setCurrentPage(1); 
    fetchNews();
  }, [selectedPeriod, propFunction, sortOption]);

  const sortNews = (newsList) => {
    return newsList.sort((a, b) => {
      switch (sortOption) {
        case 'viewCount':
          return b.viewCount - a.viewCount;
        case 'likeCount':
          return b.likeCount - a.likeCount;
        case 'commentCount':
          return b.commentCount - a.commentCount;
        case 'level':
          return a.level - b.level;
        case 'publishedAt':
          return new Date(b.publishedAt) - new Date(a.publishedAt);
        default:
          return 0;
      }
    });
  };

  // 페이지 번호 변경 함수
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  // 페이지 번호로 이동
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // 페이지 번호 렌더링
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(newlist.length / newsPerPage); i++) {
    if (i > minPageNumberLimit && i <= maxPageNumberLimit) {
      pageNumbers.push(i);
    }
  }

  // 현재 페이지에 보여질 뉴스 아이템들을 계산합니다.
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = newlist.slice(indexOfFirstNews, indexOfLastNews);
 
  // 실제 화면 표출 부분
  return (
    <>
      <div>
        {/* {currentNews.map((list, index) => (
          <UnewsItem list={list} key={index} />
        ))} */}
        {
          currentNews.length === 0 ? 
          (<p>오늘은 연관 데이터가 없습니다.</p>) : 
          currentNews.map((list, index) => (
            <UnewsItem list={list} key={index} />
          ))
        }


      </div>
      <nav>
        <ul className='pagination' style={{ marginBottom: '10px' }}>
          <li className='page-item'>
            <PageButton className='pagination-control'
              onClick={handlePrevbtn}
              disabled={currentPage === pageNumbers[0] ? true : false}
            >
              이전
            </PageButton>
          </li>
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a className='page-link'
                onClick={() => paginate(number)} href='#!' >
                {number}
              </a>
            </li>
          ))}
          <li className='page-item'>
            <PageButton className='pagination-control'
              onClick={handleNextbtn}
              disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}
            >
              다음
            </PageButton>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default UnewsList;


const PageButton = styled.button`
  background-color: #D7D6D2; /* 라이트그레이*/
  color: #213951;
  padding: 5px 10px; /* 안쪽 여백 설정 */
  font-size: 12px; /* 글자 크기 설정 */
  cursor: pointer; /* 마우스 커서 모양 설정 */
  outline: none; /* 클릭 포커스 스타일 제거 */

  border: none; /* 테두리 없음 */
  border-radius: 30px; /*30% */
  
  margin-left: 5px;
  margin-right: 5px;
`;