import React,{useState,useEffect,useRef} from 'react';

import styled from "styled-components";
import styles from "../../css/UnewsItem.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp, faComment } from "@fortawesome/free-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ModalPortal from '../modal/Portal';
import Modal from '../modal/Modal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
//   margin-right: 5px;
// `;

function UnewsItem({ list }) {
  const [modalOn, setModalOn] = useState(false);
  const startModal = () => {
    setModalOn(true);
    console.log("모달열기")
  };
  const closeModal = () => {
    setTimeout(function() {
      setModalOn(false);
    }, 200);
    console.log("모달닫기")
  };

  // 재생 시간 변환 (분:초)
  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
  // 쉼표 추가
  const formattedViewCount = list.viewCount.toLocaleString();

  // 요일 추출
  const publishedDate = new Date(list.publishedAt);
  const daysOfWeek = ["(일)", "(월)", "(화)", "(수)", "(목)", "(금)", "(토)"];
  const dayOfWeek = daysOfWeek[publishedDate.getDay()];
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDate = publishedDate.toLocaleDateString('ko-KR', options);
  const fullDate = `${formattedDate} ${dayOfWeek}`;

  // 업뎃날짜 차이 추출
  const puparts = list.publishedAt.split(' ');
  const pudatePart = puparts[0]; //년-월-일 형태
  const upparts = list.recentUpdate.split(' ');
  const updatePart = upparts[0]; //년-월-일 형태

  const updateDate = new Date(list.recentUpdate);
  const timeDifference = updateDate - publishedDate; //밀리초 단위
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // 밀리초를 일단위로 변환 및 올림
  
  // publishedAt 날짜와 비교하여 테두리 색상 결정
  const today = new Date(); // 현재 날짜와 시간
  const yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1); // 어제 날짜
  const formattedYesterday = yesterday.toISOString().slice(0, 10); // YYYY-MM-DD 형식의 문자열
  console.log(formattedYesterday);

  const isYesterday = formattedYesterday === publishedDate.toISOString().slice(0, 10);
  // const borderColor = isYesterday ? '#213951' : '#F2F2F2';
  const boxShadow = isYesterday ? '1px 1px 1px gray' : 'none';

  // AI_result에 따른 색상 및 아이콘 결정
  let aiColor = '#A5A39A'; 
  let aiIcon = null; 
  if (list.AI_result === '긍정') {
    aiIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#213951', marginLeft:'2px' }} />;
    aiColor = '#213951';
  } else if (list.AI_result === '부정') {
    aiIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#FC6C62', marginLeft:'2px' }} />;
    aiColor = '#FC6C62';
  } else if (list.AI_result === '중립') {
    aiIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#A5A39A', marginLeft:'2px' }} />;
    aiColor = '#A5A39A';
  }

  // com_result에 따른 색상 및 아이콘 결정
  let comColor = '#A5A39A'; 
  let comIcon = null; 
  if (list.com_result) { // 분석값이 있을 때만 comIcon 표출
    if (list.com_result === '긍정') {
      comIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#213951', marginLeft:'2px' }} />;
      comColor = '#213951';
    } else if (list.com_result === '부정') {
      comIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#FC6C62', marginLeft:'2px' }} />;
      comColor = '#FC6C62';
    } else if (list.com_result === '중립') {
      comIcon = <FontAwesomeIcon icon={faCircle} style={{ color: '#A5A39A', marginLeft:'2px' }} />;
      comColor = '#A5A39A';
    }
  }

  return ( 
    // <div style={{width:"100%", display:"block", justifyContent:"center"}}>
    <div style={{width:"80%", margin:"0 auto", display:"flex"}}>

      {/* <div style={{width:"50%", display:"inline-block",placeItems:"end"}}> */}
      <div style={{width:"40%"}}>
          <div className={styles.uListBlock} style = {{ boxShadow }} >
            <div className={styles.thum}><a href={list.url}><img src={list.thumb}/></a>
              <span>{formatDuration(list.duration)}</span>
            </div>
            <div className={styles.title}>{list.title}</div>
            <div className={styles.channelName}>{list.channelTitle}</div>
            <div className={styles.publishedAt}>{list.publishedAt}{dayOfWeek}</div>
            <div className={styles.viewCount}><FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />{formattedViewCount}</div>
            <div className={styles.likeCount}><FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '5px' }} />{list.likeCount}</div>
            <div className={styles.comCount}><FontAwesomeIcon icon={faComment} style={{ marginRight: '5px' }} />{list.commentCount}</div>
          </div>
      </div>

      <div style={{ width:"60%" }}>
          <div className={styles.aiBlock}  onClick={startModal} >
            <ModalPortal>
              {modalOn && <Modal modalclose={closeModal} v_id={list.videoID} />}
            </ModalPortal>
            <div className={styles.AI_summary}>요약 | {list.AI_summary}</div>
            <div className={styles.AI_result}>판단{aiIcon} | {list.AI_reason}</div>
            {comIcon && (<div className={styles.com_result}>댓글{comIcon} | {list.com_reason}</div>)}
            {/* {comIcon && (<div className={styles.recentUpdate}>{updatePart} (+{daysDifference})</div>)} */}
            <div className={styles.AI_keyword}>언급량 {list.AI_percent}% | #{list.AI_keyword}</div>
            
            {/* <p>{list.sreason}</p> */}
          </div>
      </div>

    </div>
  );
}

export default UnewsItem;
