import React from "react";

import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import '../../css/ContentBody.css'

// function Header() {
function Header({nameUser, handleButtonLogout}) {
    return (

      <div className="header" style={{  display: 'flex', alignItems: 'center' }}>
        <HeaderTop>

          <div className="HeaderContent" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <HeaderTitle>언론 리스크 대응 시스템(PoC)</HeaderTitle>
            <div className="UserContent" style={{ display:'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <CustomSpan> {nameUser} 님 
                <LogOutButton onClick={handleButtonLogout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} /> 로그아웃
                </LogOutButton>
              </CustomSpan>
            </div>
          </div> 

        </HeaderTop>
      </div>

    );
}
export default Header;

const HeaderTop = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto; /* Adjust the row height */
  white-space: nowrap; /* 텍스트를 한 줄로 유지 */
  width: 100%;
  align-items: center; 
  margin-bottom: 30px;
`;
const HeaderTitle = styled.h1`
  display: flex;
  align-items: center;
  font-family: 'NanumSquareRoundEB';
  font-size: 30px; 
  color: #213951; /* 남색 */
  margin-left: 20px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
  width: 80%;
`;

const CustomSpan = styled.span`
  display: flex;
  align-items: center;
  color: #213951;
  font-family: 'NanumSquareRoundR';
  font-size: 16px;

  margin-right : 10px;
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정   float: right;*/
`;

// 로그아웃 버튼 스타일 
const LogOutButton = styled.button`
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  background-color: #D7D6D2;
  color: #213951;
  padding: 5px 10px; /* 안쪽 여백 설정 */
  font-family: 'NanumSquareRoundR';
  font-size: 14px; /* 글자 크기 설정 */
  cursor: pointer; /* 마우스 커서 모양 설정 */
  outline: none; /* 클릭 포커스 스타일 제거 */
  width: 99px; /* 너비 설정 */
  height: 30px; /* 높이 설정 */
  border: none; /* 테두리 없음 */
  border-radius: 10px;
  margin-left : 10px;

  /* 마우스 호버시 스타일 */
  &:hover {
    background-color: #213951; 
    color: white;
  }
`;
